<template>
  <div class="index_wrap" >
    <div class="tabs_wrap" >
      <div class="tabs_item"
           :class="{ 'tabs_active': status == 0 }"
           @click="tabChange(0)"
      >可用券</div>
      <div class="tabs_item"
           :class="{ 'tabs_active': status == 1 }"
           @click="tabChange(1)"
      >不可用券</div>
    </div>
    <div class="list_wrap" v-if="status == 0" @scroll="handleScroll">
      <div class="list_item" v-for="(item, index) in list" :key="index" >
        <div class="list_left" >
          <div class="title" >{{ item.productName }}</div>
          <div class="sub_title" >{{ item.shopName }}</div>
          <img src="./img/2.png" class="line" />
          <div class="time_text" >有效期：{{ item.expireTime }}</div>
        </div>
        <div class="list_right" >
<!--          <div class="use_money" >¥0</div>-->
          <div class="use_text" >体验券</div>
        </div>
      </div>
      <van-empty
        v-if="list.length <= 0 && !loading"
        :image="empty"
        description="暂无优惠券"
      />
    </div>

    <div class="list_wrap" v-if="status == 1" @scroll="handleScroll" >
      <div class="list_item" v-for="(item, index) in list" :key="index" :class="{ 'un_used': item.status == 2 }" >
        <img src="./img/3.png" class="status_img" v-if="item.status == 1" />
        <img src="./img/4.png" class="status_img" v-if="item.status == 2" />
        <div class="list_left" >
          <div class="title" >{{ item.productName }}</div>
          <div class="sub_title" >{{ item.shopName }}</div>
          <img src="./img/2.png" class="line" />
          <div class="time_text" >有效期：{{ item.expireTime }}</div>
        </div>
        <div class="list_right" >
<!--          <div class="use_money" >¥0</div>-->
          <div class="use_text" >体验券</div>
        </div>
      </div>
      <van-empty
        v-if="list.length <= 0 && !loading"
        :image="empty"
        description="暂无优惠券"
      />
    </div>


  </div>
</template>
<style scoped lang="less" >
@import "index";
</style>
<script>
import {
  getCustomerCouponPage
} from '@/api'
import empty from '../report/img/6.png'
export default {
  name: 'coupon',
  data() {
    return {
      status: 0,
      page: 1,
      pageSize: 20,
      list: [],
      loading: false,
      empty
    }
  },
  methods: {
    handleScroll(event) {
      const {scrollTop, clientHeight, scrollHeight} = event.target
      console.log(scrollTop, clientHeight, scrollHeight, scrollTop + clientHeight > scrollHeight - 50)
      if (scrollTop + clientHeight > scrollHeight - 50){
        this.page ++
        this.getCustomerCouponPage()
      }
    },
    tabChange(status) {
      this.status = status
      this.page = 1
      this.list = []
      this.getCustomerCouponPage()
    },
    getCustomerCouponPage() {
      const { page, pageSize, status } = this
      this.loading = true
      getCustomerCouponPage({ page, pageSize, status }).then(res => {
        this.loading = false
        if(res.success) {
          this.list = this.list.concat(res.data)
        }
      })
    }
  },
  mounted() {
    this.getCustomerCouponPage()
  }
}
</script>
